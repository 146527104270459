import { useContext, useState } from "react";

import { useSearchParams } from "react-router-dom";
import { Box, IconButton, Button, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import CloseIcon from "@mui/icons-material/Close";

import {
  UserPreset,
  CurPreset,
  UserTypes,
  PergPreset,
  PergPresetsData,
} from "./DataTypes";
import { Trans } from "../Translations";
import { client } from "../RpcClient";
import { config } from "../Config";
import { vB } from "../Style";
import AdminRender from "../AdminRender";
import { PergContext } from "./PergState";

function iconCss(largerThen750: boolean) {
  return {
    pointerEvents: "none",
    [vB.xs]: {
      height: "60px",
    },
    [vB.lg]: {
      height: largerThen750 ? "80px" : "60px",
    },
  };
}

function PresetChoice(props: {
  className: string;
  selected: boolean;
  preset: PergPreset;
  onClick: () => void;
}) {
  const { dynTr } = useContext(Trans);
  const verticalLg = useMediaQuery(vB.lg);
  const largerThen750 = useMediaQuery("(min-width:750px)");
  return (
    <Button
      className={props.className}
      sx={{
        marginRight: "10px",
        minWidth: 0,
        padding: 0,
        lineHeight: "1.1em",
        opacity: props.selected ? 1.0 : 0.7,
        ":hover": {
          opacity: 1.0,
          backgroundColor: "#fff",
        },
      }}
      disabled={props.selected}
      onClick={props.onClick}
    >
      {props.preset.icon && (
        <Box
          component="img"
          onDragStart={(e) => e.preventDefault()}
          src={`${config.apiUrl}/assets/${props.preset.icon}`}
          sx={iconCss(largerThen750)}
        />
      )}
      {verticalLg && largerThen750 && (
        <Box
          sx={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}
        >
          <Typography
            component="span"
            sx={{
              fontFamily: '"Montserrat", sans-serif',
              textTransform: "none",
              // font: 'normal 100% "Open Sans", sans-serif',
              fontWeight: "bold",
              fontSize: "0.9em",
              color: "#000",
              marginBottom: "0.3em",
            }}
          >
            {dynTr(props.preset.translations, (it) => it.name)}
          </Typography>
        </Box>
      )}
    </Button>
  );
}

function UserPresetChoice(props: {
  selected: boolean;
  name: string;
  onClick: () => void;
  onDelete: () => void;
}) {
  const largerThen750 = useMediaQuery("(min-width:750px)");
  return (
    <Box
      sx={{
        display: "inline-block",
        position: "relative",
        marginRight: "15px",
      }}
    >
      <Button
        sx={{
          [vB.xs]: {
            height: "60px",
          },
          [vB.lg]: {
            height: largerThen750 ? "80px" : "60px",
          },
          minWidth: 0,
          padding: 0,
          paddingRight: props.selected ? "15px" : 0,
          lineHeight: "1.1em",
          opacity: props.selected ? 1.0 : 0.7,
          ":hover": {
            opacity: 1.0,
            backgroundColor: "#fff",
          },
        }}
        disabled={props.selected}
        onClick={props.onClick}
      >
        <Box sx={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
          <Typography
            component="span"
            sx={{
              fontFamily: '"Montserrat", sans-serif',
              textTransform: "none",
              // font: 'normal 100% "Open Sans", sans-serif',
              fontWeight: "bold",
              fontSize: "0.9em",
              color: "#000",
              marginBottom: "0.3em",
            }}
          >
            {props.name}
          </Typography>
        </Box>
      </Button>
      {props.selected && (
        <IconButton
          sx={{
            position: "absolute",
            top: "0",
            right: "-15px",
            border: "solid 1px",
            padding: "3px",
          }}
          onClick={props.onDelete}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
}

export default function PresetChooser(props: {
  presetsData: PergPresetsData | undefined;
  userPresets: UserPreset[] | undefined;
  curPreset: CurPreset | undefined;
  onPresetClick: (preset: PergPreset) => void;
  onUserPresetClick: (preset: UserPreset) => void;
  onUserPresetDelete: (preset: UserPreset) => void;
  showCurrentElements: () => void;
}) {
  const { _ } = useContext(Trans);
  const { structure, setStructure } = useContext(PergContext);
  const verticalLg = useMediaQuery(vB.lg);
  const largerThen750 = useMediaQuery("(min-width:750px)");
  const [params] = useSearchParams();
  // const curPreset = props.curPreset;
  const curPreset = (() => {
    for (const preset of props.presetsData?.data ?? []) {
      if (
        (preset.translations.filter((it) => it.languages_code === "en-US")[0]
          .name ===
          "Freestanding") ===
        structure.freestanding
      ) {
        return { isUserPreset: false, id: preset.id };
      }
    }
    return undefined;
  })();
  const [isRedCheckOn, setIsRedCheckOn] = useState<boolean>(true);
  const children = [];
  children.push(
    ...(props.presetsData?.data ?? []).map((it, index) => {
      return (
        <PresetChoice
          className={`ree-ug-preset-button-${index}`}
          key={it.id}
          selected={
            curPreset !== undefined &&
            !curPreset.isUserPreset &&
            it.id === curPreset.id
          }
          preset={it}
          onClick={
            /*() => props.onPresetClick(it)*/
            () => {
              console.log(
                "Setting freestanding to ",
                it.translations.filter(
                  (it2) => it2.languages_code === "en-US"
                )[0].name === "Freestanding"
              );
              setStructure((draft) => {
                draft.freestanding =
                  it.translations.filter(
                    (it2) => it2.languages_code === "en-US"
                  )[0].name === "Freestanding";
              });
            }
          }
        />
      );
    })
  );
  children.push(
    ...(props.userPresets ?? []).map((it) => {
      return (
        <UserPresetChoice
          key={it.id}
          selected={
            curPreset !== undefined &&
            curPreset.isUserPreset &&
            it.id === curPreset.id
          }
          name={it.name}
          onClick={() => props.onUserPresetClick(it)}
          onDelete={() => props.onUserPresetDelete(it)}
        />
      );
    })
  );
  return (
    <Box
      sx={{
        className: "ree-ug-types",
        pointerEvents: "initial",
        position: "absolute",
        left: 0,
        top: 0,
        "@media (orientation: portrait)": {
          right: 0,
        },
        "@media (orientation: landscape)": {
          right: { xs: "250px", lg: "350px", xl: "500px" },
        },
        [vB.xs]: {
          height: "60px",
        },
        [vB.lg]: {
          height: largerThen750 ? "160px" : "60px",
        },
      }}
    >
      <Box
        sx={{
          [vB.xs]: {
            display: params.get("adminKey") !== null ? "block" : "none",
          },
          [vB.lg]: {
            marginTop: "15px",
            display: largerThen750 ? "block" : "none",
            height: "60px",
          },
          display: "flex",
          alignItems: "center",
        }}
      >
        {largerThen750 && (
          <Box component="a" href="/" onDragStart={(e) => e.preventDefault()}>
            <Box
              component="img"
              onDragStart={(e) => e.preventDefault()}
              sx={{ height: "40px", marginLeft: "25px" }}
              src="imgs/afu_logo.svg"
            />
          </Box>
        )}

        {params.get("adminKey") !== null && (
          <Button
            sx={{ position: "absolute", right: "210px" }}
            onClick={props.showCurrentElements}
            variant="outlined"
          >
            Elements
          </Button>
        )}
        {params.get("adminKey") !== null && (
          <Box
            sx={{
              display: "inline-flex",
              position: "absolute",
              right: "485px",
            }}
          >
            <AdminRender />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          className="ree-ug-presets"
          sx={{
            marginLeft: verticalLg && largerThen750 ? "20px" : "5px",
            // display: "flex",
            [vB.xs]: {
              // alignItems: "center",
              // gap: "10px",
              height: "60px",
            },
            [vB.lg]: {
              // alignItems: "center",
              // gap: "25px",
              height: largerThen750 ? "80px" : "60px",
            },
            overflow: "auto",
            whiteSpace: "nowrap",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}
