import { useState, useContext, SyntheticEvent } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Trans } from "../Translations";
import TextOptionsChooser from "../TextOptionsChooser";
import ImageOptionsChooser from "../ImageOptionsChooser";
import { client } from "../RpcClient";
import { PergContext } from "./PergState";
import { arrFindFirst } from "../Util";

interface GenericOption {
  id: number;
  name: string;
  value: string;
}

export default function SidePanels(props: {}) {
  const theme = useTheme();
  const lgOrLarger = useMediaQuery(theme.breakpoints.up("lg"));
  const isPortrait = useMediaQuery("@media (orientation: portrait)");
  const { _ } = useContext(Trans);
  const { structure, setStructure } = useContext(PergContext);
  const panelsTypes = [
    {
      id: 0,
      name: _("Fixed side panels"),
      value: "fixed",
    },
    {
      id: 1,
      name: _("Sliding system"),
      value: "sliding",
    },
    {
      id: 2,
      name: _("None"),
      value: "none",
    },
  ];
  const glassThickness = [
    {
      id: 0,
      name: _("8mm"),
      value: "8mm",
    },
    {
      id: 1,
      name: _("10mm"),
      value: "10mm",
    },
  ];
  const glassType = [
    {
      id: 0,
      name: _("Normal glass"),
      value: "afu-glass1",
    },
    {
      id: 1,
      name: _("Milk glass"),
      value: "afu-glass2",
    },
  ];
  const colorOptions = [
    {
      id: 0,
      name: "/imgs/afu_color_dark_grey.jpg",
      value: "afu-fabricgrey",
    },
    {
      id: 1,
      name: "/imgs/afu_color_grey.jpg",
      value: "afu-fabricwhite",
    },
  ];
  const awningTypes = [
    {
      id: 0,
      name: _("Vertical awning"),
      value: "markise",
    },
    {
      id: 1,
      name: _("None"),
      value: "none",
    },
  ];
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const positionToNiceName = (val: string) => {
    if (val === "left") {
      return _("Left");
    } else if (val === "right") {
      return _("Right");
    } else if (val === "front") {
      return _("Front");
    } else if (val === "back") {
      return _("Rear");
    } else {
      throw Error();
    }
  };
  return (
    <Box>
      {structure.side_panels.map((panelData, panelDataIndex) => {
        const panelId = panelData.position + (panelData.sub_pos + 1).toString();
        return (
          <Accordion
            expanded={expanded === panelId}
            onChange={handleChange(panelId)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${panelId}bh-content`}
              id={`${panelId}bh-header`}
            >
              <Typography
                sx={{ flexGrow: 1, flexShrink: 0, marginRight: "5px" }}
              >
                {positionToNiceName(panelData.position).toUpperCase() +
                  " " +
                  (panelData.sub_pos + 1).toString()}
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                {
                  arrFindFirst(panelsTypes, (it) => it.value === panelData.type)
                    .name
                }
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextOptionsChooser<GenericOption>
                mainTitle={_("Choose side panels type")}
                options={panelsTypes}
                getTitle={(option) => option.name}
                getId={(option) => option.id}
                selectedOption={arrFindFirst(
                  panelsTypes,
                  (it) => it.value === panelData.type
                )}
                setSelectedOption={(option) =>
                  setStructure((draft) => {
                    draft.side_panels[panelDataIndex].type = option.value;
                  })
                }
                isPopup={false}
                columns={3}
              />
              {panelData.type !== "none" && (
                <TextOptionsChooser<GenericOption>
                  mainTitle={_("Choose glass thickness")}
                  options={glassThickness}
                  getTitle={(option) => option.name}
                  getId={(option) => option.id}
                  selectedOption={arrFindFirst(
                    glassThickness,
                    (it) => it.value === panelData.glass_type
                  )}
                  setSelectedOption={(option) =>
                    setStructure((draft) => {
                      draft.side_panels[panelDataIndex].glass_type =
                        option.value;
                    })
                  }
                  isPopup={false}
                  columns={2}
                />
              )}
              {panelData.type !== "none" && (
                <TextOptionsChooser<GenericOption>
                  mainTitle={_("Choose glass type")}
                  options={glassType}
                  getTitle={(option) => option.name}
                  getId={(option) => option.id}
                  selectedOption={arrFindFirst(
                    glassType,
                    (it) => it.value === panelData.glass_material
                  )}
                  setSelectedOption={(option) =>
                    setStructure((draft) => {
                      draft.side_panels[panelDataIndex].glass_material =
                        option.value;
                    })
                  }
                  isPopup={false}
                  columns={2}
                />
              )}
              <TextOptionsChooser<GenericOption>
                mainTitle={_("Choose vertical awning")}
                options={awningTypes}
                getTitle={(option) => option.name}
                getId={(option) => option.id}
                selectedOption={arrFindFirst(
                  awningTypes,
                  (it) => it.value === panelData.awning_type
                )}
                setSelectedOption={(option) =>
                  setStructure((draft) => {
                    draft.side_panels[panelDataIndex].awning_type =
                      option.value;
                  })
                }
                isPopup={false}
                columns={2}
              />
              {panelData.awning_type !== "none" && (
                <ImageOptionsChooser<GenericOption>
                  mainTitle={_("Awning material")}
                  options={colorOptions}
                  getImage={(option) => option.name}
                  getId={(option) => option.id}
                  selectedOption={arrFindFirst(
                    colorOptions,
                    (it) => it.value === panelData.awning_material
                  )}
                  setSelectedOption={(option) =>
                    setStructure((draft) => {
                      draft.side_panels[panelDataIndex].awning_material =
                        option.value;
                    })
                  }
                  isPopup={false}
                />
              )}
              {/*
              <Typography>
                Nulla facilisi. Phasellus sollicitudin nulla et quam mattis
                feugiat. Aliquam eget maximus est, id dignissim quam.
              </Typography>
              */}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}
