import { useState, useEffect, createContext, PropsWithChildren } from "react";

const gettextJs = (window as any).i18n();

const defaultLocale = (window as any).reeExternalConfig.defaultLocale;

interface TranslationsItem {
  languages_code: string;
}

interface TranslationsContextObject {
  locale: string;
  changeLocale: (newLocale: string) => Promise<void>;
  _: (msgid: string, ...args: any[]) => string;
  dynTr: <T extends TranslationsItem>(
    translations: Array<T>,
    valueFn: (it: T) => string
  ) => string;
}

export const Trans = createContext<TranslationsContextObject>({
  locale: "xx_XX",
  changeLocale: async (newLocale) => {},
  _: (str) => str,
  dynTr: (translations, valueFn) =>
    dynStr(translations, valueFn, "en-US") ?? "",
});

async function loadLanguage(locale: string): Promise<void> {
  if (locale !== "en_US") {
    gettextJs.loadJSON(
      await fetch(`translations/${locale}.po.json?${Date.now()}`).then(
        (response) => response.json()
      ),
      "messages"
    );
  }
  gettextJs.setLocale(locale);
}

interface TranslationsProps {}
export default function Translations(
  props: PropsWithChildren<TranslationsProps>
) {
  const [locale, setLocale] = useState<string>(defaultLocale);
  useEffect(() => {
    loadLanguage(defaultLocale);
  }, []);
  return (
    <Trans.Provider
      value={{
        locale: locale,
        changeLocale: async (newLocale: string) => {
          await loadLanguage(newLocale);
          setLocale(newLocale);
        },
        _: (msgid, ...args) => {
          return gettextJs.gettext(msgid, ...args);
        },
        dynTr: (translations, valueFn) =>
          dynStr(translations, valueFn, locale.replace("_", "-")) ?? "",
      }}
    >
      {props.children}
    </Trans.Provider>
  );
}

function dynStrWithoutFallback<T extends TranslationsItem>(
  translations: Array<T>,
  valueFn: (it: T) => string,
  lang: string
): string | null {
  for (const trans of translations) {
    if (trans.languages_code === lang) {
      const value = valueFn(trans);
      if (value !== undefined && value !== null && typeof value === "string") {
        return value;
      } else {
        return null;
      }
    }
  }
  return null;
}

function dynStr<T extends TranslationsItem>(
  translations: Array<T>,
  valueFn: (it: T) => string,
  lang: string
): string | null {
  const value = dynStrWithoutFallback(translations, valueFn, lang);
  if (lang !== "en-US" && value === null) {
    return dynStrWithoutFallback(translations, valueFn, "en-US");
  } else {
    return value;
  }
}
