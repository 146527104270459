import { useState, ReactNode, useEffect, useContext, useRef } from "react";

import { useSearchParams } from "react-router-dom";
import {
  Fab,
  Box,
  SpeedDial,
  SpeedDialAction,
  Button,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import useMediaQuery from "@mui/material/useMediaQuery";

import { client } from "../RpcClient";
import { vB } from "../Style";
import { Trans } from "../Translations";

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: 0,
    right: 0,
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    top: 0,
    left: 0,
  },
}));

interface Action {
  icon: string;
  name: string;
}

function marginLeft(): string {
  return "max(var(--sal), 15px)";
}

function imgToIcon(imgSrc: string): ReactNode {
  return (
    <Box
      component="img"
      sx={{ height: "100%", pointerEvents: "none" }}
      src={imgSrc}
    />
  );
}

function actionToImg(action: Action): ReactNode {
  return imgToIcon(`imgs/afu_icon_${action.icon}.png`);
}

function RoomChooser(props: {
  wasTouched: boolean;
  setLoadingLevel: (val: boolean) => void;
}) {
  const { _ } = useContext(Trans);
  let actions: Action[] = [
    { icon: "blank", name: _("Blank") },
    { icon: "house", name: _("House") },
  ];
  const dialRef = useRef<HTMLElement>();
  const [params] = useSearchParams();
  if (params.get("adminKey") !== null) {
    actions.push({ icon: "empty", name: "EMPTY" });
  }
  const verticalLg = useMediaQuery(vB.lg);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [curAction, setCurAction] = useState<Action>(actions[0]);
  (window as any).reeSofaRoomRestore = async function () {
    const actionIndex = actions.findIndex((it) => it.icon === curAction.icon);
    if (actionIndex === -1) {
      console.error("Couldn't find room");
      return;
    }
    /*
    await client.request("SofaSwitchToRoom", {
      new_room_index: actionIndex,
    });
    */
  };
  useEffect(() => {
    const onTouch = function (e: any) {
      if (
        props.wasTouched &&
        dialRef.current &&
        !dialRef.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };
    // Equivalent to componentDidMount
    document.addEventListener("touchstart", onTouch, true);
    // Equivalent to componentWillUnmount
    return () => {
      document.removeEventListener("touchstart", onTouch, true);
    };
  }, [props, setIsOpen]);
  const dialKey =
    "room-chooser" + (props.wasTouched ? "controlled" : "uncontrolled");

  return (
    <Box
      sx={{
        position: "relative",
        marginLeft: marginLeft(),
        marginTop: 0,
        height: verticalLg ? "72px" : "50px",
      }}
    >
      <StyledSpeedDial
        key={dialKey}
        ref={dialRef}
        ariaLabel="Choose Your Room"
        icon={
          <Fab
            size={verticalLg ? "large" : "small"}
            onClick={props.wasTouched ? () => setIsOpen(!isOpen) : undefined}
          >
            {actionToImg(curAction)}
          </Fab>
        }
        open={props.wasTouched ? isOpen : undefined}
        onClose={props.wasTouched ? () => setIsOpen(false) : undefined}
        direction="right"
        FabProps={{
          size: verticalLg ? "large" : "small",
        }}
        sx={{ height: verticalLg ? "auto" : "50px" }}
      >
        {actions
          .filter((it) => it.icon !== curAction.icon)
          .map((action) => {
            const actionIndex = actions.findIndex(
              (it) => it.icon === action.icon
            );
            if (actionIndex === -1) {
              throw Error();
            }
            return (
              <SpeedDialAction
                key={action.name}
                icon={actionToImg(action)}
                tooltipTitle={action.name}
                FabProps={{
                  size: verticalLg ? "large" : "small",
                  onClick: async () => {
                    props.setLoadingLevel(true);
                    const curLevel =
                      action.icon == "blank"
                        ? "/Game/ShowcaseRoom"
                        : "/Game/SchunkOutdoors";
                    (window as any).reeCurrentLevel = curLevel;
                    await client.request("SwitchLevel", {
                      level_name: curLevel,
                    });
                    /*
                    await client.request("SofaSwitchToRoom", {
                      new_room_index: actionIndex,
                    });
                    */
                    setCurAction(action);
                    // props.updateRoomDimensions();
                  },
                }}
              />
            );
          })}
      </StyledSpeedDial>
    </Box>
  );
}

type DimensionError = "room_to_small" | "min_dimension" | "none";

function DimensionInput(props: {
  id: string;
  label: string;
  curError: DimensionError;
  defaultValue: string;
}) {
  const { _ } = useContext(Trans);
  const [num, setNum] = useState(props.defaultValue);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setNum(e.target.value);
    }
  };
  let helperText;
  if (props.curError === "room_to_small") {
    helperText = _("Room to small for your sofa");
  } else if (props.curError === "min_dimension") {
    helperText = _("Minimum size is 250cm");
  }

  return (
    <TextField
      margin="dense"
      id={props.id}
      error={props.curError !== "none"}
      helperText={helperText}
      label={props.label}
      variant="standard"
      type="text"
      sx={{ display: "block" }}
      InputProps={{
        endAdornment: <InputAdornment position="end">cm</InputAdornment>,
      }}
      inputProps={{
        inputMode: "numeric",
        pattern: "[0-9]*",
      }}
      onChange={(e) => handleChange(e)}
      value={num}
    />
  );
}

function cameraActionToImg(action: Action): ReactNode {
  return imgToIcon(`imgs/camera_${action.icon}.svg`);
}

function CameraPresetChooser(props: { wasTouched: boolean }) {
  const { _ } = useContext(Trans);
  const verticalLg = useMediaQuery(vB.lg);
  const dialRef = useRef<HTMLElement>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const cameraPresets: Action[] = [
    { icon: "2d", name: _("2D view") },
    { icon: "3d_1", name: _("3D view left") },
    { icon: "3d_2", name: _("3D view right") },
  ];
  const switchToCamera = async (action: Action) => {
    await client.request("SofaSwitchToCamera", {
      camera_preset: action.icon,
    });
  };
  useEffect(() => {
    const onTouch = function (e: any) {
      if (
        props.wasTouched &&
        dialRef.current &&
        !dialRef.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };
    // Equivalent to componentDidMount
    document.addEventListener("touchstart", onTouch, true);

    // Equivalent to componentWillUnmount
    return () => {
      document.removeEventListener("touchstart", onTouch, true);
    };
  }, [props, setIsOpen]);
  const dialKey =
    "camera-chooser" + (props.wasTouched ? "controlled" : "uncontrolled");
  return (
    <Box
      sx={{
        position: "relative",
        marginLeft: marginLeft(),
        marginTop: verticalLg ? "7.5px" : 0,
        height: verticalLg ? "72px" : "50px",
      }}
    >
      <StyledSpeedDial
        key={dialKey}
        ref={dialRef}
        ariaLabel="Izberite pogled"
        icon={
          <Fab
            size={verticalLg ? "large" : "small"}
            onClick={props.wasTouched ? () => setIsOpen(!isOpen) : undefined}
          >
            {cameraActionToImg({ icon: "default", name: "Spremeni pogled" })}
          </Fab>
        }
        direction="right"
        open={props.wasTouched ? isOpen : undefined}
        onClose={props.wasTouched ? () => setIsOpen(false) : undefined}
        FabProps={{
          size: verticalLg ? "large" : "small",
        }}
      >
        {cameraPresets.map((action) => {
          return (
            <SpeedDialAction
              key={action.name}
              icon={cameraActionToImg(action)}
              tooltipTitle={action.name}
              FabProps={{
                size: verticalLg ? "large" : "small",
                onClick: async () => {
                  switchToCamera(action);
                },
              }}
            />
          );
        })}
      </StyledSpeedDial>
    </Box>
  );
}

setInterval(() => {
  const cb = (window as any).reeSofaSaveCameraPosition;
  if (cb !== undefined) {
    cb();
  }
}, 10 * 1000);

export default function ActionBar(props: {
  setLoadingLevel: (val: boolean) => void;
}) {
  const { _ } = useContext(Trans);
  const verticalLg = useMediaQuery(vB.lg);
  const largerThen750 = useMediaQuery("(min-width:750px)");
  // const [autoSnap, setAutoSnap] = useState<boolean>(true);
  const [open, setOpen] = useState(false);
  const [wasTouched, setWasTouched] = useState(
    (window as any).reeWasTouched ?? false
  );
  /*
  const updateRoomDimensions = props.updateRoomDimensions;
  (window as any).reeActionBarRestore = async function () {
    if (props.showMeasures) {
      await client.request("SofaSwitchMeasures", {});
    }
    if (!autoSnap) {
      await client.request("SofaSwitchAutoSnap", {});
    }
    await (window as any).reeSofaRoomRestore();
    const position = (window as any).reeSofaCameraPosition;
    if (position === undefined) {
      console.error();
    } else {
      await client.request("SofaCameraPositionRestore", position);
    }
  };
  useEffect(() => {
    updateRoomDimensions();
  }, []);
  */
  useEffect(() => {
    const updateTouchSupport = function () {
      (window as any).reeWasTouched = true;
      if (!wasTouched) {
        setWasTouched(true);
      }
    };
    // Equivalent to componentDidMount
    document.addEventListener("touchstart", updateTouchSupport, true);

    // Equivalent to componentWillUnmount
    return () => {
      document.removeEventListener("touchstart", updateTouchSupport, true);
    };
  }, [wasTouched, setWasTouched]);
  return (
    <Box
      sx={{
        position: "absolute",
        [vB.xs]: {
          top: "60px",
        },
        [vB.lg]: {
          top: largerThen750 ? "160px" : "60px",
        },
        left: 0,
        width: 0,
        pointerEvents: "initial",
        zIndex: 1100,
      }}
    >
      {/*
      <Box
        sx={{
          marginLeft: marginLeft(),
          marginTop: verticalLg ? "15px" : "7.5px",
          display: "block",
        }}
      >
        <Fab
          size={verticalLg ? "large" : "small"}
          onClick={async () => {
            props.setShowMeasures(
              (await client.request("SofaSwitchMeasures", {})).show_measures
            );
          }}
          aria-label="show_measures"
        >
          {imgToIcon(`imgs/measure_${props.showMeasures ? "on" : "off"}.svg`)}
        </Fab>
      </Box>
      <CameraPresetChooser wasTouched={wasTouched} />
      */}
      <Box
        sx={{
          height: verticalLg ? "15px" : "7.5px",
        }}
      />
      <RoomChooser
        wasTouched={wasTouched}
        setLoadingLevel={props.setLoadingLevel}
      />
    </Box>
  );
}
