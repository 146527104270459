import { useState, useEffect, createContext, PropsWithChildren } from "react";

import { Updater, useImmer } from "use-immer";

import { client } from "../RpcClient";
import { ReeRPCParamsPergStructureUpdate } from "../generated/GeneratedTypes";

export function defaultSidePanel(position: string, sub_pos: number) {
  return {
    position: position,
    sub_pos: sub_pos,
    type: "none",
    glass_type: "8mm",
    glass_material: "afu-glass1",
    awning_type: "none",
    awning_material: "afu-fabricgrey",
  };
}

const startingState = {
  panel_material: "afu-black",
  width: 400,
  depth: 300,
  height_front: 250,
  height_back: 300,
  strong_front: false,
  freestanding: true,
  inset_front: 0,
  inset_back: 0,
  roof_glass_type: "8mm",
  roof_glass_material: "afu-glass1",
  roof_awning_type: "none",
  roof_awning_material: "afu-fabricgrey",
  side_panels: [
    defaultSidePanel("left", 0),
    defaultSidePanel("front", 0),
    defaultSidePanel("right", 0),
    defaultSidePanel("back", 0),
  ],
};

export const PergContext = createContext<{
  structure: ReeRPCParamsPergStructureUpdate;
  setStructure: Updater<ReeRPCParamsPergStructureUpdate>;
}>({
  structure: startingState,
  setStructure: (draftFunction) => {
    throw Error("PergState mutated before init");
  },
});

export default function PergState(props: PropsWithChildren<{}>) {
  const [structure, setStructure] =
    useImmer<ReeRPCParamsPergStructureUpdate>(startingState);
  useEffect(() => {
    client.request("PergStructureUpdate", structure);
  }, [structure]);
  return (
    <PergContext.Provider
      value={{
        structure: structure,
        setStructure: setStructure,
      }}
    >
      {props.children}
    </PergContext.Provider>
  );
}
