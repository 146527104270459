import { useState, useContext } from "react";

import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Trans } from "../Translations";
import TextOptionsChooser from "../TextOptionsChooser";
import ImageOptionsChooser from "../ImageOptionsChooser";
import { client } from "../RpcClient";
import { PergContext } from "./PergState";
import { arrFindFirst } from "../Util";

interface GenericOption {
  id: number;
  name: string;
  value: string;
}

export default function Roof(props: {}) {
  const theme = useTheme();
  const lgOrLarger = useMediaQuery(theme.breakpoints.up("lg"));
  const isPortrait = useMediaQuery("@media (orientation: portrait)");
  const { _ } = useContext(Trans);
  const glassThickness = [
    {
      id: 0,
      name: _("8mm"),
      value: "8mm",
    },
    {
      id: 1,
      name: _("10mm"),
      value: "10mm",
    },
  ];
  const glassType = [
    {
      id: 0,
      name: _("Normal glass"),
      value: "afu-glass1",
    },
    {
      id: 1,
      name: _("Milk glass"),
      value: "afu-glass2",
    },
  ];
  const awningOptions = [
    {
      id: 0,
      name: _("Under roof"),
      value: "under_roof",
    },
    {
      id: 1,
      name: _("On roof"),
      value: "on_roof",
    },
    {
      id: 2,
      name: _("None"),
      value: "none",
    },
  ];
  const colorOptions = [
    {
      id: 0,
      name: "/imgs/afu_color_dark_grey.jpg",
      value: "afu-fabricgrey",
    },
    {
      id: 1,
      name: "/imgs/afu_color_grey.jpg",
      value: "afu-fabricwhite",
    },
  ];
  const { structure, setStructure } = useContext(PergContext);
  return (
    <Box>
      <TextOptionsChooser<GenericOption>
        mainTitle={_("Choose glass thickness")}
        options={glassThickness}
        getTitle={(option) => option.name}
        getId={(option) => option.id}
        selectedOption={arrFindFirst(
          glassThickness,
          (it) => it.value === structure.roof_glass_type
        )}
        setSelectedOption={(option) =>
          setStructure((draft) => {
            draft.roof_glass_type = option.value;
          })
        }
        isPopup={false}
        columns={2}
      />
      <TextOptionsChooser<GenericOption>
        mainTitle={_("Choose glass type")}
        options={glassType}
        getTitle={(option) => option.name}
        getId={(option) => option.id}
        selectedOption={arrFindFirst(
          glassType,
          (it) => it.value === structure.roof_glass_material
        )}
        setSelectedOption={(option) =>
          setStructure((draft) => {
            draft.roof_glass_material = option.value;
          })
        }
        isPopup={false}
        columns={2}
      />
      <TextOptionsChooser<GenericOption>
        mainTitle={_("Awning position")}
        options={awningOptions}
        getTitle={(option) => option.name}
        getId={(option) => option.id}
        selectedOption={arrFindFirst(
          awningOptions,
          (it) => it.value === structure.roof_awning_type
        )}
        setSelectedOption={(option) =>
          setStructure((draft) => {
            draft.roof_awning_type = option.value;
          })
        }
        isPopup={false}
        columns={3}
      />
      {structure.roof_awning_type !== "none" && (
        <ImageOptionsChooser<GenericOption>
          mainTitle={_("Choose awning material")}
          options={colorOptions}
          getImage={(option) => option.name}
          getId={(option) => option.id}
          selectedOption={arrFindFirst(
            colorOptions,
            (it) => it.value === structure.roof_awning_material
          )}
          setSelectedOption={(option) => {
            setStructure((draft) => {
              draft.roof_awning_material = option.value;
            });
          }}
          isPopup={false}
        />
      )}
    </Box>
  );
}
